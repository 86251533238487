/*
	Theme Default css
*/

/*---------------------------------------
    - Margin & Padding
-----------------------------------------*/

/*-- Margin Top --*/

.mt-5 {
    margin-top: 5px;
}

.mt-10 {
    margin-top: 10px;
}

.mt-15 {
    margin-top: 15px;
}

.mt-20 {
    margin-top: 20px;
}

.mt-22 {
    margin-top: 22px;
}

.mt-25 {
    margin-top: 25px;
}

.mt-30 {
    margin-top: 30px;
}

.mt-35 {
    margin-top: 35px;
}

.mt-40 {
    margin-top: 40px;
}

.mt-45 {
    margin-top: 45px;
}

.mt-50 {
    margin-top: 50px;
}

.mt-55 {
    margin-top: 55px;
}

.mt-60 {
    margin-top: 60px;
}

.mt-65 {
    margin-top: 65px;
}

.mt-70 {
    margin-top: 70px;
}

.mt-75 {
    margin-top: 75px;
}

.mt-80 {
    margin-top: 80px;
}

.mt-85 {
    margin-top: 85px;
}

.mt-90 {
    margin-top: 90px;
}

.mt-95 {
    margin-top: 95px;
}

.mt-100 {
    margin-top: 100px;
}

.mt-105 {
    margin-top: 105px;
}

.mt-110 {
    margin-top: 110px;
}

.mt-115 {
    margin-top: 115px;
}

.mt-120 {
    margin-top: 120px;
}

.mt-125 {
    margin-top: 125px;
}

.mt-130 {
    margin-top: 130px;
}

.mt-135 {
    margin-top: 135px;
}

.mt-140 {
    margin-top: 140px;
}

.mt-145 {
    margin-top: 145px;
}

.mt-150 {
    margin-top: 150px;
}

.mt-155 {
    margin-top: 155px;
}

.mt-160 {
    margin-top: 160px;
}

.mt-165 {
    margin-top: 165px;
}

.mt-170 {
    margin-top: 170px;
}

.mt-175 {
    margin-top: 175px;
}

.mt-180 {
    margin-top: 180px;
}

.mt-185 {
    margin-top: 185px;
}

.mt-190 {
    margin-top: 190px;
}

.mt-195 {
    margin-top: 195px;
}

.mt-200 {
    margin-top: 200px;
}

/*-- Margin Bottom --*/

.mb-5 {
    margin-bottom: 5px;
}

.mb-10 {
    margin-bottom: 10px;
}

.mb-13 {
    margin-bottom: 13px;
}

.mb-15 {
    margin-bottom: 15px;
}

.mb-20 {
    margin-bottom: 20px;
}

.mb-22 {
    margin-bottom: 22px;
}

.mb-25 {
    margin-bottom: 25px;
}

.mb-30 {
    margin-bottom: 30px;
}

.mb-33 {
    margin-bottom: 33px;
}

.mb-35 {
    margin-bottom: 35px;
}

.mb-38 {
    margin-bottom: 38px;
}

.mb-40 {
    margin-bottom: 40px;
}

.mb-45 {
    margin-bottom: 45px;
}

.mb-50 {
    margin-bottom: 50px;
}

.mb-55 {
    margin-bottom: 55px;
}

.mb-60 {
    margin-bottom: 60px;
}

.mb-65 {
    margin-bottom: 65px;
}

.mb-67 {
    margin-bottom: 67px;
}

.mb-70 {
    margin-bottom: 70px;
}

.mb-75 {
    margin-bottom: 75px;
}

.mb-80 {
    margin-bottom: 80px;
}

.mb-85 {
    margin-bottom: 85px;
}

.mb-90 {
    margin-bottom: 90px;
}

.mb-95 {
    margin-bottom: 95px;
}

.mb-100 {
    margin-bottom: 100px;
}

.mb-105 {
    margin-bottom: 105px;
}

.mb-110 {
    margin-bottom: 110px;
}

.mb-115 {
    margin-bottom: 115px;
}

.mb-120 {
    margin-bottom: 120px;
}

.mb-122 {
    margin-bottom: 122px;
}

.mb-125 {
    margin-bottom: 125px;
}

.mb-126 {
    margin-bottom: 126px;
}

.mb-130 {
    margin-bottom: 130px;
}

.mb-135 {
    margin-bottom: 135px;
}

.mb-140 {
    margin-bottom: 140px;
}

.mb-145 {
    margin-bottom: 145px;
}

.mb-150 {
    margin-bottom: 150px;
}

.mb-155 {
    margin-bottom: 155px;
}

.mb-160 {
    margin-bottom: 160px;
}

.mb-165 {
    margin-bottom: 165px;
}

.mb-170 {
    margin-bottom: 170px;
}

.mb-175 {
    margin-bottom: 175px;
}

.mb-180 {
    margin-bottom: 180px;
}

.mb-185 {
    margin-bottom: 185px;
}

.mb-190 {
    margin-bottom: 190px;
}

.mb-195 {
    margin-bottom: 195px;
}

.mb-200 {
    margin-bottom: 200px;
}

.mb-205 {
    margin-bottom: 205px;
}

.mb-210 {
    margin-bottom: 210px;
}

.mb-215 {
    margin-bottom: 215px;
}

.mb-220 {
    margin-bottom: 220px;
}

.mb-225 {
    margin-bottom: 225px;
}

.mb-230 {
    margin-bottom: 230px;
}

.mb-235 {
    margin-bottom: 235px;
}

.mb-240 {
    margin-bottom: 240px;
}

.mb-245 {
    margin-bottom: 245px;
}

.mb-250 {
    margin-bottom: 250px;
}

.mb-255 {
    margin-bottom: 255px;
}

.mb-260 {
    margin-bottom: 260px;
}

.mb-265 {
    margin-bottom: 265px;
}

.mb-270 {
    margin-bottom: 270px;
}

.mb-275 {
    margin-bottom: 275px;
}

.mb-280 {
    margin-bottom: 280px;
}

.mb-285 {
    margin-bottom: 285px;
}

.mb-290 {
    margin-bottom: 290px;
}

.mb-295 {
    margin-bottom: 295px;
}

.mb-300 {
    margin-bottom: 300px;
}

/*-- Margin Left --*/

.ml-5 {
    margin-left: 5px;
}

.ml-10 {
    margin-left: 10px;
}

.ml-15 {
    margin-left: 15px;
}

.ml-20 {
    margin-left: 20px;
}

.ml-25 {
    margin-left: 25px;
}

.ml-30 {
    margin-left: 30px;
}

.ml-35 {
    margin-left: 35px;
}

.ml-40 {
    margin-left: 40px;
}

.ml-45 {
    margin-left: 45px;
}

.ml-50 {
    margin-left: 50px;
}

.ml-55 {
    margin-left: 55px;
}

.ml-60 {
    margin-left: 60px;
}

.ml-65 {
    margin-left: 65px;
}

.ml-70 {
    margin-left: 70px;
}

.ml-75 {
    margin-left: 75px;
}

.ml-80 {
    margin-left: 80px;
}

.ml-85 {
    margin-left: 85px;
}

.ml-90 {
    margin-left: 90px;
}

.ml-95 {
    margin-left: 95px;
}

.ml-100 {
    margin-left: 100px;
}

.ml-105 {
    margin-left: 105px;
}

.ml-110 {
    margin-left: 110px;
}

.ml-115 {
    margin-left: 115px;
}

.ml-120 {
    margin-left: 120px;
}

.ml-125 {
    margin-left: 125px;
}

.ml-130 {
    margin-left: 130px;
}

.ml-135 {
    margin-left: 135px;
}

.ml-140 {
    margin-left: 140px;
}

.ml-145 {
    margin-left: 145px;
}

.ml-150 {
    margin-left: 150px;
}

.ml-155 {
    margin-left: 155px;
}

.ml-160 {
    margin-left: 160px;
}

.ml-165 {
    margin-left: 165px;
}

.ml-170 {
    margin-left: 170px;
}

.ml-175 {
    margin-left: 175px;
}

.ml-180 {
    margin-left: 180px;
}

.ml-185 {
    margin-left: 185px;
}

.ml-190 {
    margin-left: 190px;
}

.ml-195 {
    margin-left: 195px;
}

.ml-200 {
    margin-left: 200px;
}

/*-- Margin Right --*/

.mr-5 {
    margin-right: 5px;
}

.mr-10 {
    margin-right: 10px;
}

.mr-15 {
    margin-right: 15px;
}

.mr-20 {
    margin-right: 20px;
}

.mr-25 {
    margin-right: 25px;
}

.mr-30 {
    margin-right: 30px;
}

.mr-35 {
    margin-right: 35px;
}

.mr-40 {
    margin-right: 40px;
}

.mr-45 {
    margin-right: 45px;
}

.mr-50 {
    margin-right: 50px;
}

.mr-55 {
    margin-right: 55px;
}

.mr-60 {
    margin-right: 60px;
}

.mr-65 {
    margin-right: 65px;
}

.mr-70 {
    margin-right: 70px;
}

.mr-75 {
    margin-right: 75px;
}

.mr-80 {
    margin-right: 80px;
}

.mr-85 {
    margin-right: 85px;
}

.mr-90 {
    margin-right: 90px;
}

.mr-95 {
    margin-right: 95px;
}

.mr-100 {
    margin-right: 100px;
}

.mr-105 {
    margin-right: 105px;
}

.mr-110 {
    margin-right: 110px;
}

.mr-115 {
    margin-right: 115px;
}

.mr-120 {
    margin-right: 120px;
}

.mr-125 {
    margin-right: 125px;
}

.mr-130 {
    margin-right: 130px;
}

.mr-135 {
    margin-right: 135px;
}

.mr-140 {
    margin-right: 140px;
}

.mr-145 {
    margin-right: 145px;
}

.mr-150 {
    margin-right: 150px;
}

.mr-155 {
    margin-right: 155px;
}

.mr-160 {
    margin-right: 160px;
}

.mr-165 {
    margin-right: 165px;
}

.mr-170 {
    margin-right: 170px;
}

.mr-175 {
    margin-right: 175px;
}

.mr-180 {
    margin-right: 180px;
}

.mr-185 {
    margin-right: 185px;
}

.mr-190 {
    margin-right: 190px;
}

.mr-195 {
    margin-right: 195px;
}

.mr-200 {
    margin-right: 200px;
}

/*-- Padding Top --*/

.pt-5 {
    padding-top: 5px;
}

.pt-10 {
    padding-top: 10px;
}

.pt-15 {
    padding-top: 15px;
}

.pt-20 {
    padding-top: 20px;
}

.pt-25 {
    padding-top: 25px;
}

.pt-30 {
    padding-top: 30px;
}

.pt-35 {
    padding-top: 35px;
}

.pt-40 {
    padding-top: 40px;
}

.pt-45 {
    padding-top: 45px;
}

.pt-50 {
    padding-top: 50px;
}

.pt-55 {
    padding-top: 55px;
}

.pt-60 {
    padding-top: 60px;
}

.pt-65 {
    padding-top: 65px;
}

.pt-70 {
    padding-top: 70px;
}

.pt-75 {
    padding-top: 75px;
}

.pt-80 {
    padding-top: 80px;
}

.pt-85 {
    padding-top: 85px;
}

.pt-90 {
    padding-top: 90px;
}

.pt-95 {
    padding-top: 95px;
}

.pt-100 {
    padding-top: 100px;
}

.pt-105 {
    padding-top: 105px;
}

.pt-110 {
    padding-top: 110px;
}

.pt-115 {
    padding-top: 115px;
}

.pt-120 {
    padding-top: 120px;
}

.pt-122 {
    padding-top: 122px;
}

.pt-125 {
    padding-top: 125px;
}

.pt-128 {
    padding-top: 128px;
}

.pt-129 {
    padding-top: 129px;
}

.pt-130 {
    padding-top: 130px;
}

.pt-135 {
    padding-top: 135px;
}

.pt-140 {
    padding-top: 140px;
}

.pt-145 {
    padding-top: 145px;
}

.pt-148 {
    padding-top: 148px;
}

.pt-149 {
    padding-top: 149px;
}

.pt-150 {
    padding-top: 150px;
}

.pt-155 {
    padding-top: 155px;
}

.pt-160 {
    padding-top: 160px;
}

.pt-165 {
    padding-top: 165px;
}

.pt-170 {
    padding-top: 170px;
}

.pt-175 {
    padding-top: 175px;
}

.pt-180 {
    padding-top: 180px;
}

.pt-185 {
    padding-top: 185px;
}

.pt-190 {
    padding-top: 190px;
}

.pt-195 {
    padding-top: 195px;
}

.pt-200 {
    padding-top: 200px;
}

.pt-205 {
    padding-top: 205px;
}

.pt-210 {
    padding-top: 210px;
}

.pt-215 {
    padding-top: 215px;
}

.pt-216 {
    padding-top: 216px;
}

.pt-220 {
    padding-top: 220px;
}

.pt-225 {
    padding-top: 225px;
}

.pt-230 {
    padding-top: 230px;
}

.pt-235 {
    padding-top: 235px;
}

.pt-240 {
    padding-top: 240px;
}

.pt-245 {
    padding-top: 245px;
}

.pt-250 {
    padding-top: 250px;
}

.pt-255 {
    padding-top: 255px;
}

.pt-260 {
    padding-top: 260px;
}

.pt-265 {
    padding-top: 265px;
}

.pt-270 {
    padding-top: 270px;
}

.pt-275 {
    padding-top: 275px;
}

.pt-280 {
    padding-top: 280px;
}

.pt-285 {
    padding-top: 285px;
}

.pt-290 {
    padding-top: 290px;
}

.pt-295 {
    padding-top: 295px;
}

.pt-300 {
    padding-top: 300px;
}

/*-- Padding Bottom --*/

.pb-5 {
    padding-bottom: 5px;
}

.pb-10 {
    padding-bottom: 10px;
}

.pb-15 {
    padding-bottom: 15px;
}

.pb-20 {
    padding-bottom: 20px;
}

.pb-25 {
    padding-bottom: 25px;
}

.pb-30 {
    padding-bottom: 30px;
}

.pb-35 {
    padding-bottom: 35px;
}

.pb-40 {
    padding-bottom: 40px;
}

.pb-43 {
    padding-bottom: 43px;
}

.pb-45 {
    padding-bottom: 45px;
}

.pb-50 {
    padding-bottom: 50px;
}

.pb-55 {
    padding-bottom: 55px;
}

.pb-60 {
    padding-bottom: 60px;
}

.pb-65 {
    padding-bottom: 65px;
}

.pb-70 {
    padding-bottom: 70px;
}

.pb-75 {
    padding-bottom: 75px;
}

.pb-80 {
    padding-bottom: 80px;
}

.pb-85 {
    padding-bottom: 85px;
}

.pb-88 {
    padding-bottom: 88px;
}

.pb-90 {
    padding-bottom: 90px;
}

.pb-93 {
    padding-bottom: 93px;
}

.pb-95 {
    padding-bottom: 95px;
}

.pb-98 {
    padding-bottom: 98px;
}

.pb-100 {
    padding-bottom: 100px;
}

.pb-105 {
    padding-bottom: 105px;
}

.pb-108 {
    padding-bottom: 108px;
}

.pb-109 {
    padding-bottom: 109px;
}

.pb-110 {
    padding-bottom: 110px;
}

.pb-115 {
    padding-bottom: 115px;
}

.pb-117 {
    padding-bottom: 117px;
}

.pb-120 {
    padding-bottom: 120px;
}

.pb-123 {
    padding-bottom: 123px;
}

.pb-125 {
    padding-bottom: 125px;
}

.pb-129 {
    padding-bottom: 129px;
}

.pb-130 {
    padding-bottom: 130px;
}

.pb-135 {
    padding-bottom: 135px;
}

.pb-140 {
    padding-bottom: 140px;
}

.pb-145 {
    padding-bottom: 145px;
}

.pb-147 {
    padding-bottom: 147px;
}

.pb-150 {
    padding-bottom: 150px;
}

.pb-155 {
    padding-bottom: 155px;
}

.pb-160 {
    padding-bottom: 160px;
}

.pb-165 {
    padding-bottom: 165px;
}

.pb-170 {
    padding-bottom: 170px;
}

.pb-175 {
    padding-bottom: 175px;
}

.pb-180 {
    padding-bottom: 180px;
}

.pb-185 {
    padding-bottom: 185px;
}

.pb-190 {
    padding-bottom: 190px;
}

.pb-195 {
    padding-bottom: 195px;
}

.pb-200 {
    padding-bottom: 200px;
}

.pb-205 {
    padding-bottom: 205px;
}

.pb-210 {
    padding-bottom: 210px;
}

.pb-215 {
    padding-bottom: 215px;
}

.pb-220 {
    padding-bottom: 220px;
}

.pb-225 {
    padding-bottom: 225px;
}

.pb-230 {
    padding-bottom: 230px;
}

.pb-235 {
    padding-bottom: 235px;
}

.pb-240 {
    padding-bottom: 240px;
}

.pb-245 {
    padding-bottom: 245px;
}

.pb-250 {
    padding-bottom: 250px;
}

.pb-255 {
    padding-bottom: 255px;
}

.pb-260 {
    padding-bottom: 260px;
}

.pb-265 {
    padding-bottom: 265px;
}

.pb-270 {
    padding-bottom: 270px;
}

.pb-275 {
    padding-bottom: 275px;
}

.pb-280 {
    padding-bottom: 280px;
}

.pb-285 {
    padding-bottom: 285px;
}

.pb-290 {
    padding-bottom: 290px;
}

.pb-295 {
    padding-bottom: 295px;
}

.pb-300 {
    padding-bottom: 300px;
}

/*-- Padding Left --*/

.pl-5 {
    padding-left: 5px;
}

.pl-10 {
    padding-left: 10px;
}

.pl-15 {
    padding-left: 15px;
}

.pl-20 {
    padding-left: 20px;
}

.pl-25 {
    padding-left: 25px;
}

.pl-30 {
    padding-left: 30px;
}

.pl-35 {
    padding-left: 35px;
}

.pl-40 {
    padding-left: 40px;
}

.pl-45 {
    padding-left: 45px;
}

.pl-50 {
    padding-left: 50px;
}

.pl-55 {
    padding-left: 55px;
}

.pl-60 {
    padding-left: 60px;
}

.pl-65 {
    padding-left: 65px;
}

.pl-70 {
    padding-left: 70px;
}

.pl-75 {
    padding-left: 75px;
}

.pl-80 {
    padding-left: 80px;
}

.pl-85 {
    padding-left: 85px;
}

.pl-90 {
    padding-left: 90px;
}

.pl-95 {
    padding-left: 95px;
}

.pl-100 {
    padding-left: 100px;
}

.pl-105 {
    padding-left: 105px;
}

.pl-110 {
    padding-left: 110px;
}

.pl-115 {
    padding-left: 115px;
}

.pl-120 {
    padding-left: 120px;
}

.pl-125 {
    padding-left: 125px;
}

.pl-130 {
    padding-left: 130px;
}

.pl-135 {
    padding-left: 135px;
}

.pl-140 {
    padding-left: 140px;
}

.pl-145 {
    padding-left: 145px;
}

.pl-150 {
    padding-left: 150px;
}

.pl-155 {
    padding-left: 155px;
}

.pl-160 {
    padding-left: 160px;
}

.pl-165 {
    padding-left: 165px;
}

.pl-170 {
    padding-left: 170px;
}

.pl-175 {
    padding-left: 175px;
}

.pl-180 {
    padding-left: 180px;
}

.pl-185 {
    padding-left: 185px;
}

.pl-190 {
    padding-left: 190px;
}

.pl-195 {
    padding-left: 195px;
}

.pl-200 {
    padding-left: 200px;
}

/*-- Padding Right --*/

.pr-5 {
    padding-right: 5px;
}

.pr-10 {
    padding-right: 10px;
}

.pr-15 {
    padding-right: 15px;
}

.pr-20 {
    padding-right: 20px;
}

.pr-25 {
    padding-right: 25px;
}

.pr-30 {
    padding-right: 30px;
}

.pr-35 {
    padding-right: 35px;
}

.pr-40 {
    padding-right: 40px;
}

.pr-45 {
    padding-right: 45px;
}

.pr-50 {
    padding-right: 50px;
}

.pr-55 {
    padding-right: 55px;
}

.pr-60 {
    padding-right: 60px;
}

.pr-65 {
    padding-right: 65px;
}

.pr-70 {
    padding-right: 70px;
}

.pr-75 {
    padding-right: 75px;
}

.pr-80 {
    padding-right: 80px;
}

.pr-85 {
    padding-right: 85px;
}

.pr-90 {
    padding-right: 90px;
}

.pr-95 {
    padding-right: 95px;
}

.pr-100 {
    padding-right: 100px;
}

.pr-105 {
    padding-right: 105px;
}

.pr-110 {
    padding-right: 110px;
}

.pr-115 {
    padding-right: 115px;
}

.pr-120 {
    padding-right: 120px;
}

.pr-125 {
    padding-right: 125px;
}

.pr-130 {
    padding-right: 130px;
}

.pr-135 {
    padding-right: 135px;
}

.pr-140 {
    padding-right: 140px;
}

.pr-145 {
    padding-right: 145px;
}

.pr-150 {
    padding-right: 150px;
}

.pr-155 {
    padding-right: 155px;
}

.pr-160 {
    padding-right: 160px;
}

.pr-165 {
    padding-right: 165px;
}

.pr-170 {
    padding-right: 170px;
}

.pr-175 {
    padding-right: 175px;
}

.pr-180 {
    padding-right: 180px;
}

.pr-185 {
    padding-right: 185px;
}

.pr-190 {
    padding-right: 190px;
}

.pr-195 {
    padding-right: 195px;
}

.pr-200 {
    padding-right: 200px;
}